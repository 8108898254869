<template>
    <div>
      <b-row class="mt-2 match-height">
        <!-- <b-col xl="4" md="4" sm="6">
          <settings-card icon="SettingsIcon" statistic="General Settings" link="general" statistic-title="General Settings" color="secondary" />
        </b-col> -->
        <!-- <b-col xl="4" md="4" sm="6">
          <settings-card icon="UsersIcon" :statistic="$t('settings.menu.manage_teams')" link="manage-teams" statistic-title="Manage Teams" color="primary" />
        </b-col> -->
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_currencies')">
          <settings-card icon="CrosshairIcon" :statistic="$t('settings.menu.manage_currencies')" link="manage-currencies" statistic-title="Manage Currencies" color="success" />
        </b-col>

        <b-col xl="4" md="4" sm="6" v-if="$Can('access_cost_centers')">
          <settings-card icon="DollarSignIcon" :statistic="$t('settings.menu.manage_cost_centers')" link="manage-cost-centers" statistic-title="Manage Cost Centers" color="success" />
        </b-col>

        <b-col xl="4" md="4" sm="6" v-if="$Can('access_users')">
          <settings-card icon="UsersIcon" :statistic="$t('settings.menu.manage_users')" link="manage-users" statistic-title="Manage Users" color="success" />
        </b-col>
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_intermediaries')">
          <settings-card icon="VoicemailIcon" :statistic="$t('settings.menu.manage_intermediaries')" link="manage-intermediaries" statistic-title="Manage Intermediaries" color="warning" />
        </b-col>
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_default_tickets')">
          <settings-card icon="UserCheckIcon" :statistic="$t('settings.menu.default_personal_settings')" link="manage-default-tickets" statistic-title="Manage Default Ticket Visibility" color="info" />
        </b-col>
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_emails')">
          <settings-card icon="MailIcon" :statistic="$t('settings.menu.manage_emails')" link="manage-emails" statistic-title="Manage Emails" color="warning" />
        </b-col> 
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_holidays')">
          <settings-card icon="BookmarkIcon" :statistic="$t('settings.menu.manage_holidays')" link="manage-holidays" statistic-title="Manage Holidays" color="warning" />
        </b-col>   
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_timeline')">
          <settings-card icon="DiscIcon" :statistic="$t('settings.menu.action_timeline')" link="action-timeline" statistic-title="Action Time Line" color="danger" />
        </b-col> 
        <b-col xl="4" md="4" sm="6" v-if="$Can('access_tax_rates')">
          <settings-card icon="PercentIcon" :statistic="$t('settings.menu.manage_tax_rates')" link="manage-tax-rates" statistic-title="Manage Tax Rates" color="success" />
        </b-col>   

        <b-col xl="4" md="4" sm="6" v-if="$Can('access_commissions')">
          <settings-card icon="PercentIcon" :statistic="$t('settings.menu.manage_commissions')" link="manage-commissions" statistic-title="Manage Commissions" color="info" />
        </b-col>   

      </b-row>
    </div>
  </template>
  
  <script>
  import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from 'bootstrap-vue';
  
  import store from '@/store';
  import { avatarText } from '@core/utils/filter';
  import { onUnmounted } from '@vue/composition-api';
  import settingsStoreModule from '../settingsStoreModule';
  import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      BTable,
      BFormSelect,
      BPagination,
  
      SettingsCard,
    },
    data() {
        return {
            
        }
    },
    methods: {},
  
    setup() {
      const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
      // Register module
      if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
      });
    },
    mounted() {},
  };
  </script>
  